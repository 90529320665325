import styled from '@emotion/styled';
import type { ThemeType } from 'radiance-ui/lib/constants';
import { MEDIA_QUERIES, SPACER, GRID } from 'radiance-ui/lib/constants';
import { TYPOGRAPHY_STYLE } from 'radiance-ui';
import { css } from '@emotion/react';

export const MainContainer = styled.div`
  padding: 0 ${SPACER.large};
  max-width: ${GRID.xlContentMaxWidth};
  margin: 0 auto;
`;

export const PageHeader = styled.h1`
  ${({ theme }) => TYPOGRAPHY_STYLE.display(theme)};
  font-family: ${({ theme }) => theme.FONTS.headerFont};
  font-weight: ${({ theme }) => theme.TYPOGRAPHY.fontWeight.normal};
  margin-top: 51px;

  ${MEDIA_QUERIES.smUp} {
    margin-top: 86px;
  }
`;

export const descriptionTextStyles = (theme: ThemeType) => css`
  max-width: 464px;
  margin-top: ${SPACER.medium};
  ${TYPOGRAPHY_STYLE.body(theme)};
`;

export const blogListHeadingStyles = (spotlightBlogPostRoute: boolean) => css`
  margin-top: ${spotlightBlogPostRoute ? 0 : SPACER.x2large};
`;

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  justify-content: start;
  grid-column-gap: ${SPACER.large};
  margin: 0 auto;
  margin-top: ${SPACER.medium};

  ${MEDIA_QUERIES.smUp} {
    grid-template-columns: repeat(2, minmax(247px, 1fr));
    margin-top: ${SPACER.large};
  }

  ${MEDIA_QUERIES.lgUp} {
    grid-template-columns: repeat(3, minmax(298px, 1fr));
  }
`;
