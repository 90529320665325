import { ContentfulImage } from 'components/global/ContentfulImage';
import SchemaError from 'components/global/errors/SchemaError';
import { title } from 'process';
import type { SpotlightBlogPostRouteEntry } from 'schema';
import { SpotlightBlogPostRoute } from './SpotlightBlogPostRoute';

export const ContentfulSpotlightBlogPostRoute = ({
  path,
  page,
}: SpotlightBlogPostRouteEntry) => {
  if (page._type === 'schemaError') {
    return <SchemaError {...page} />;
  }

  const { _updatedAt, headerImage } = page;

  return (
    <SpotlightBlogPostRoute
      {...page}
      path={path}
      articleDate={new Date(_updatedAt).toLocaleDateString('en-US', {
        day: 'numeric',
        month: 'short',
      })}
      headerImage={
        <ContentfulImage
          image={headerImage}
          alt={headerImage.title || title}
          loading="eager"
        />
      }
    />
  );
};
