import { getBlogListPath } from 'utils/getBlogListPath';

export const getLinks = (
  currentPage: number,
  slug: string | undefined,
  numPages: number,
  numLinksToDisplay: number,
) => {
  const links: Array<{ path: string; pageNum: number }> = [];

  const addLink = (linkSlug: string | undefined, pageNum: number) => {
    if (pageNum <= numPages && pageNum > 0) {
      links.push({
        pageNum,
        path: getBlogListPath(linkSlug, pageNum),
      });
    }
  };

  Array.from({ length: numLinksToDisplay }).forEach((_link, index) => {
    if (numPages <= numLinksToDisplay || currentPage < numLinksToDisplay) {
      /* 
        Display the first x links where the active link can be any of the links.
        Ex:( [1] 2 3 ) or ( 1 [2] 3 ) or ( 1 2 [3] )
        x = numLinksToDisplay
      */
      const pageNum = index + 1;
      addLink(slug, pageNum);
    } else if (
      /* 
        Display the last x links where the active link can be any of the links.
        Ex: ( [8] 9 10 ) or ( 8 [9] 10 ) or ( 8 9 [10] )
      */
      numPages - currentPage + 1 <=
      numLinksToDisplay - Math.floor(numLinksToDisplay / 2)
    ) {
      const pageNum = numPages - numLinksToDisplay + index + 1;
      addLink(slug, pageNum);
    } else {
      /* 
        Display x links where the active link is in the center.
        Ex: ( 4 [5] 6 ) or ( 12 [13] 14 )
      */
      const pageNum = currentPage + (index - Math.floor(numLinksToDisplay / 2));
      addLink(slug, pageNum);
    }
  });

  return links;
};
