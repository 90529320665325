import styled from '@emotion/styled';
import { Link } from 'gatsby';
import { SPACER, ANIMATION, MEDIA_QUERIES } from 'radiance-ui/lib/constants';
import { TYPOGRAPHY_STYLE } from 'radiance-ui';

export const CardLink = styled(Link)`
  position: relative;
  display: flex;
  flex-direction: column;
  margin: ${SPACER.xlarge} 0;
  border: 1px solid ${({ theme }) => theme.COLORS.border};
  box-shadow: ${({ theme }) => theme.BOX_SHADOWS.clickable};
  transition: ${ANIMATION.defaultTiming};
  text-decoration: none;
  padding-bottom: ${SPACER.xlarge};

  ${MEDIA_QUERIES.smUp} {
    flex-direction: row;
    padding-bottom: 0;
    margin: ${SPACER.xlarge} 0 ${SPACER.x3large};
  }

  &:hover {
    box-shadow: ${({ theme }) => theme.BOX_SHADOWS.clickableHover};
    text-decoration: none;
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  flex: 1;

  .gatsby-image-wrapper {
    width: 100%;
    height: 216px;

    ${MEDIA_QUERIES.smUp} {
      height: 309px;
    }
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
`;

export const InnerTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${SPACER.xlarge} ${SPACER.xlarge};
`;

export const ReadTime = styled.span`
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  align-self: flex-end;
  ${({ theme }) => TYPOGRAPHY_STYLE.label(theme)};
  font-weight: ${({ theme }) => theme.TYPOGRAPHY.fontWeight.bold};
  color: ${({ theme }) => theme.COLORS.primaryTint2};
  letter-spacing: 1px;
  white-space: nowrap;
  margin: 0 ${SPACER.medium} ${SPACER.medium} 0;
  text-transform: uppercase;
`;

export const Title = styled.h3`
  ${({ theme }) => TYPOGRAPHY_STYLE.title(theme)};
  font-weight: ${({ theme }) => theme.TYPOGRAPHY.fontWeight.bold};
  margin-bottom: ${SPACER.medium};
`;

export const Label = styled.span`
  ${({ theme }) => TYPOGRAPHY_STYLE.button(theme)};
  font-weight: ${({ theme }) => theme.TYPOGRAPHY.fontWeight.bold};
  color: ${({ theme }) => theme.COLORS.primaryTint2};
  margin-bottom: ${SPACER.medium};
`;

export const Description = styled.p`
  ${({ theme }) => TYPOGRAPHY_STYLE.body(theme)};
  color: ${({ theme }) => theme.COLORS.primaryTint2};
  line-height: 1.5;
`;
