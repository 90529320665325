import React from 'react';

import {
  CardLink,
  ImageContainer,
  TextContainer,
  ReadTime,
  Title,
  Description,
} from './BlogPostCard.style';

export type BlogCardProps = {
  updateDate: string;
  path: string;
  headerImage: React.ReactNode;
  title: string;
  subtitle: string;
  readTimeInMinutes?: number;
};

export const BlogPostCard = ({
  updateDate,
  path,
  headerImage,
  title,
  subtitle,
  readTimeInMinutes,
}: BlogCardProps) => (
  <CardLink data-testid="blog-post" to={path}>
    <ImageContainer>{headerImage}</ImageContainer>
    <TextContainer>
      <div>
        <Title>{title}</Title>
        <Description>{subtitle}</Description>
      </div>
      <ReadTime>
        <strong>{updateDate}</strong>
        {!!readTimeInMinutes && ` • ${readTimeInMinutes} min read`}
      </ReadTime>
    </TextContainer>
  </CardLink>
);
