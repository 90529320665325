import { Link } from 'gatsby';
import React from 'react';
import { ArrowLeftIcon, ArrowRightIcon } from 'radiance-ui/lib/icons';

import { getLinks } from './PaginationLinks.utils';
import {
  PaginationWrapper,
  linkStyles,
  OuterPaginationLink,
  BoundsLinkWrapper,
  InnerPaginationLinksWrapper,
  Spacer,
} from './PaginationLinks.style';
import { getBlogListPath } from 'utils/getBlogListPath';

interface PaginationLinksProps {
  totalPages: number;
  page: number;
  slug?: string;
}

export const PaginationLinks = ({
  page,
  totalPages,
  slug,
}: PaginationLinksProps) => {
  const numLinksToDisplay = 5;

  const shouldShowPreviousLink = page > 1;
  const shouldShowNextLink = page < totalPages;
  const shouldShowFirstPageLink = page > numLinksToDisplay;
  const shouldShowLastPageLink =
    totalPages > numLinksToDisplay && page < totalPages - numLinksToDisplay + 1;

  return (
    <PaginationWrapper>
      {shouldShowPreviousLink ? (
        <OuterPaginationLink
          to={getBlogListPath(slug, page - 1)}
          aria-label="previous page"
        >
          <ArrowLeftIcon /> <span>Previous</span>
        </OuterPaginationLink>
      ) : (
        <Spacer />
      )}
      <InnerPaginationLinksWrapper>
        <BoundsLinkWrapper shouldShow={shouldShowFirstPageLink}>
          <Link css={linkStyles()} to={getBlogListPath(slug, 1)}>
            1
          </Link>
          <span css={linkStyles()}>...</span>
        </BoundsLinkWrapper>
        <div>
          {getLinks(page, slug, totalPages, numLinksToDisplay).map(
            ({ path, pageNum }) => (
              <Link css={linkStyles(pageNum === page)} key={pageNum} to={path}>
                {pageNum}
              </Link>
            ),
          )}
        </div>
        <BoundsLinkWrapper shouldShow={shouldShowLastPageLink}>
          <span css={linkStyles()}>...</span>
          <Link css={linkStyles()} to={getBlogListPath(slug, totalPages)}>
            {totalPages}
          </Link>
        </BoundsLinkWrapper>
      </InnerPaginationLinksWrapper>
      {shouldShowNextLink ? (
        <OuterPaginationLink
          to={getBlogListPath(slug, page + 1)}
          aria-label="next page"
        >
          <span>Next</span> <ArrowRightIcon />
        </OuterPaginationLink>
      ) : (
        <Spacer isNext />
      )}
    </PaginationWrapper>
  );
};
