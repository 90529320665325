import {
  CardLink,
  Description,
  ImageContainer,
  InnerTextContainer,
  Label,
  ReadTime,
  TextContainer,
  Title,
} from './SpotlightBlogPostRoute.style';

type SpotlightBlogPostRouteProps = {
  path: string;
  headerImage: React.ReactNode;
  title: string;
  subtitle: string;
  readTimeInMinutes?: number;
  articleDate: string;
};

export const SpotlightBlogPostRoute = ({
  path,
  headerImage,
  title,
  subtitle,
  readTimeInMinutes,
  articleDate,
}: SpotlightBlogPostRouteProps) => (
  <CardLink data-testid="spotlight-article" to={path}>
    <ImageContainer>{headerImage}</ImageContainer>
    <TextContainer>
      <InnerTextContainer>
        <Label>Featured Article</Label>
        <Title>{title}</Title>
        <Description>{subtitle}</Description>
      </InnerTextContainer>
    </TextContainer>
    <ReadTime>
      {articleDate}
      {!!readTimeInMinutes && ` • ${readTimeInMinutes} min read`}
    </ReadTime>
  </CardLink>
);
