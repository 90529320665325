import type { PageProps } from 'gatsby';
import React from 'react';

import { Footer } from 'components/global/Footer';
import { Nav } from 'components/global/Nav';
import { SEO } from 'components/global/SEO';
import { DISCLAIMER_ARTICLE } from 'consts/copy';
import type { BlogTagBasicEntry, BlogTagEntry } from 'schema';
import { RouteForBlogListCardsEntry } from 'utils/contentful/api';

import { isBlogTagIndex } from 'utils/contentful/utils/isBlogTagIndex';
import * as S from './BlogList.style';
import {
  PageHeader,
  blogListHeadingStyles,
  descriptionTextStyles,
} from './BlogList.style';
import { ContentfulBlogPostCard } from './components/BlogPostCard';
import { CategoryList } from './components/CategoryList';
import { PaginationLinks } from './components/PaginationLinks';
import { ContentfulSpotlightBlogPostRoute } from './components/SpotlightBlogPostRoute';

interface BlogListData {
  tag: BlogTagEntry;
  routes: RouteForBlogListCardsEntry[];
  mainBlogTags: BlogTagBasicEntry[];
  page: number;
  totalPages: number;
}

const BlogList = ({ pageContext }: PageProps<object, BlogListData>) => {
  const { tag, routes, page, totalPages, mainBlogTags } = pageContext;

  const isIndex = isBlogTagIndex(tag);
  const documentTitleWithPageNum = page === 1 ? '' : `Results Page ${page} -`;
  const documentTitleWithTags = `Curology ${isIndex ? '' : tag.title} Blog`;
  const documentTitle = `${documentTitleWithPageNum} ${documentTitleWithTags}`;
  const headingText = tag.title ? `${tag.title} Blog Posts` : `Blog Posts`;

  return (
    <React.Fragment>
      <SEO title={documentTitle} />
      <Nav />
      <S.MainContainer>
        <PageHeader>{isIndex ? 'The Topical' : headingText}</PageHeader>
        {tag.pageDescription && (
          <p css={descriptionTextStyles}>{tag.pageDescription}</p>
        )}
        <p css={descriptionTextStyles}>{DISCLAIMER_ARTICLE}</p>
        <CategoryList categories={mainBlogTags} />
        {tag.spotlightBlogPostRoute && (
          <ContentfulSpotlightBlogPostRoute {...tag.spotlightBlogPostRoute} />
        )}
        <h3 css={blogListHeadingStyles(!!tag.spotlightBlogPostRoute)}>
          Latest from Curology
        </h3>
        <S.GridContainer>
          {routes.map((route, index) => (
            <ContentfulBlogPostCard key={index} {...route} />
          ))}
        </S.GridContainer>
        <PaginationLinks
          slug={isIndex ? undefined : tag.slug}
          page={page}
          totalPages={totalPages}
        />
      </S.MainContainer>
      <Footer />
    </React.Fragment>
  );
};

export default BlogList;
