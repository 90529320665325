import { useLocation } from '@gatsbyjs/reach-router';

import type { BlogTagBasicEntry } from 'schema';

import { CategoryLink, CategoryListWrapper } from './CategoryList.style';
import { getBlogListPath } from 'utils/getBlogListPath';

type CategoryListProps = {
  categories: BlogTagBasicEntry[];
};

const moduleName = 'categoryList';

export const CategoryList = ({ categories }: CategoryListProps) => {
  const allCategories = [
    {
      slug: undefined,
      title: 'All',
    },
    ...categories,
  ];

  const { pathname } = useLocation();

  return (
    <CategoryListWrapper data-module={moduleName}>
      {allCategories.map(({ title, slug }) => {
        const path = getBlogListPath(slug);

        const isPathActive =
          pathname.startsWith(path) && (slug || !pathname.includes('/tag/'));

        return (
          <CategoryLink
            to={path}
            key={path}
            aria-current={isPathActive ? 'page' : undefined}
          >
            {title}
          </CategoryLink>
        );
      })}
    </CategoryListWrapper>
  );
};
