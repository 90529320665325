import styled from '@emotion/styled';
import { Link } from 'gatsby';
import { SPACER, ANIMATION } from 'radiance-ui/lib/constants';
import { TYPOGRAPHY_STYLE } from 'radiance-ui';

export const CardLink = styled(Link)`
  border: 1px solid ${({ theme }) => theme.COLORS.border};
  box-shadow: ${({ theme }) => theme.BOX_SHADOWS.clickable};
  margin-bottom: ${SPACER.xlarge};
  transition: ${ANIMATION.defaultTiming};
  text-decoration: none;

  &:hover {
    box-shadow: ${({ theme }) => theme.BOX_SHADOWS.clickableHover};
    text-decoration: none;
  }
`;

export const ImageContainer = styled.div`
  height: 205px;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;

  .gatsby-image-wrapper {
    width: 100%;
    min-height: 205px;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: ${SPACER.xlarge} ${SPACER.large} 0;
  position: relative;
  height: calc(100% - 205px);
`;

export const ReadTime = styled.span`
  ${({ theme }) => TYPOGRAPHY_STYLE.label(theme)};
  color: ${({ theme }) => theme.COLORS.primaryTint2};
  letter-spacing: 1px;
  align-self: flex-end;
  margin: ${SPACER.small} -${SPACER.small} ${SPACER.small} 0;
  text-transform: uppercase;
`;

export const Title = styled.h3`
  ${({ theme }) => TYPOGRAPHY_STYLE.title(theme)};
  font-weight: ${({ theme }) => theme.TYPOGRAPHY.fontWeight.normal};
  margin-bottom: ${SPACER.small};
`;

export const Description = styled.p`
  ${({ theme }) => TYPOGRAPHY_STYLE.body(theme)};
  color: ${({ theme }) => theme.COLORS.primaryTint2};
  line-height: 1.5;
`;
