import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Link } from 'gatsby';
import { MEDIA_QUERIES, SPACER } from 'radiance-ui/lib/constants';

export const PaginationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: ${SPACER.xlarge} 0 ${SPACER.x3large} 0;
`;

export const linkStyles = (isActive?: boolean) => css`
  margin: 0 ${SPACER.medium};
  text-decoration: none;
  font-weight: ${isActive ? 'bold' : 'auto'};
`;

export const OuterPaginationLink = styled(Link)`
  text-decoration: none;
  display: flex;
  align-items: center;

  & span {
    padding: 0 ${SPACER.medium};
    margin-top: 2px; // vertically centers font
    display: none;

    ${MEDIA_QUERIES.mdUp} {
      display: inline;
    }
  }
`;

export const Spacer = styled.div<{ isNext?: boolean }>`
  width: ${SPACER.medium};

  ${MEDIA_QUERIES.mdUp} {
    width: ${({ isNext }) => (isNext ? '82px' : '108px')};
  }
`;

export const InnerPaginationLinksWrapper = styled.div`
  display: flex;
  justify-content: space-around;
`;

export const BoundsLinkWrapper = styled.div<{ shouldShow: boolean }>`
  display: none;

  ${MEDIA_QUERIES.mdUp} {
    display: ${({ shouldShow }) => (shouldShow ? 'flex' : 'none')};
  }
`;
