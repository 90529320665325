import { ContentfulImage } from 'components/global/ContentfulImage';
import type { RouteForBlogListCardsEntry } from 'utils/contentful/api';
import { BlogPostCard } from './BlogPostCard';

export const ContentfulBlogPostCard = ({
  path,
  page,
}: RouteForBlogListCardsEntry) => {
  const { publishedAt, headerImage } = page;

  return (
    <BlogPostCard
      {...page}
      path={path}
      updateDate={new Date(publishedAt).toLocaleDateString('en-US', {
        day: 'numeric',
        month: 'short',
      })}
      headerImage={
        <ContentfulImage
          image={headerImage}
          alt={headerImage.title || page.title}
        />
      }
    />
  );
};
