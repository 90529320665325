import styled from '@emotion/styled';
import { MEDIA_QUERIES, SPACER, Z_SCALE } from 'radiance-ui/lib/constants';
import { Link } from 'gatsby';

export const CategoryListWrapper = styled.div`
  position: relative;
  margin: ${SPACER.xlarge} 0;
  margin-bottom: 0;
  padding: ${SPACER.small} 0;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
  white-space: nowrap;
  padding-bottom: 0;

  ${MEDIA_QUERIES.mdUp} {
    overflow: none;
    flex-wrap: wrap;
  }

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    display: block;
    background: ${({ theme }) => `${theme.COLORS.border}`};
    height: 1px;
    bottom: 0px;
    left: 0;
  }
`;

export const CategoryLink = styled(Link)<{ isActive?: boolean }>`
  position: relative;
  text-transform: uppercase;
  font-weight: bold;
  text-decoration: none;
  letter-spacing: 1px;
  margin: ${SPACER.small} ${SPACER.large} 0 0;
  padding-bottom: ${SPACER.small};

  font-size: ${({ theme }) => theme.TYPOGRAPHY.fontSize.button};
  color: ${({ theme }) => theme.COLORS.primaryTint2};

  &[aria-current] {
    color: ${({ theme }) => theme.COLORS.primary};
    &:after {
      background: ${({ theme }) => theme.COLORS.primary};
    }

    &:hover {
      &:after {
        background: ${({ theme }) => `${theme.COLORS.primary}`};
      }
    }
  }

  &:hover {
    &:after {
      background: ${({ theme }) => `${theme.COLORS.primaryTint2}`};
    }
  }

  &:after {
    content: '';
    position: absolute;
    display: block;
    background: transparent;
    width: 100%;
    height: 2px;
    bottom: 0px;
    left: 0;
    z-index: ${Z_SCALE.e2};
  }
`;
